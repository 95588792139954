import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { environment } from '../../environments/environment'
import {
    SponsorWidget,
    SponsorWidgetComponents,
} from '../components/SponsorWidget'
import { isLocalEnv } from '../helpers/is-local-env'
import { useUploadPublicAssetLocal } from '../hooks/useUploadPublicAssetLocal'
import { useGetPresignedUrl } from '../hooks/useGetPresignedUrl'

export function Sponsors() {
    // const history = useHistory()
    const [uploadFile] = useUploadPublicAssetLocal()
    const [getSignedUrl] = useGetPresignedUrl()
    // const [configureSeason] = useConfigureSeason()
    const { oidcUser } = useReactOidc()
    const [cacheBuster, setCachebuster] = useState(Date.now())
    const toast = useToast()

    const [sponsors, setSponsors] = useState<SponsorWidgetComponents[]>([])
    const [newSponsor, setNewSponsor] = useState<
        SponsorWidgetComponents | undefined
    >(undefined)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | undefined>(undefined)

    useEffect(() => {
        fetchSponsorData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cacheBuster, oidcUser])

    function handleClickNewSponsor() {
        // @ts-ignore - randomUUID() actually does exist on crypto, typescript doesn't believe me
        setNewSponsor({ id: crypto.randomUUID().toString() })
    }

    // Combines the input data with the rest of the sponsors and uploads that file in place of the original
    async function handleSaveSponsor(sponsor: SponsorWidgetComponents) {
        // Updates sponsor data if a pre-existing sponsor is being saved
        const updatedSponsors = sponsors.map((s) => {
            if (sponsor.id === s.id) {
                return sponsor
            } else {
                return s
            }
        })

        // Updates sponsor data with new sponsor if the new one is being saved
        if (newSponsor?.id === sponsor.id) {
            updatedSponsors.push(sponsor)
            setNewSponsor(undefined)
        }
        writeSponsorData(updatedSponsors)
    }

    async function handleDeleteSponsor(sponsor: SponsorWidgetComponents) {
        if (newSponsor?.id === sponsor.id) {
            // Deleting the new sponsor, no need to write to file
            setNewSponsor(undefined)
        } else {
            // Deleting a pre-existing sponsor
            const updatedSponsors = sponsors.filter((s) => s.id !== sponsor.id)
            writeSponsorData(updatedSponsors)
        }
    }

    async function fetchSponsorData() {
        setLoading(true)
        fetch(
            isLocalEnv()
                ? `${environment.apiGatewayUrl}/public-assets/sponsors-data.json?cachebust=${cacheBuster}`
                : `${environment.frontendUrl}/public-assets/sponsors-data.json?cachebust=${cacheBuster}`,
            {
                method: 'GET',
                cache: 'no-store',
            },
        )
            .then(async (resp) => {
                setLoading(false)
                if (resp.status !== 200) {
                    toast({
                        title: "Couldn't retrieve sponsor data.",
                        description:
                            'Please check the console and contact the dev team 💀💀💀',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                    console.error('Error retrieving sponsor data', resp)
                    setError("Couldn't retrieve sponsor data.")
                } else {
                    const fetchedResp: any = await resp.json()
                    console.debug('Fetched sponsor data.', fetchedResp)
                    setSponsors(fetchedResp)
                }
            })
            .catch((e) => {
                setLoading(false)
                setError("Couldn't retrieve sponsor data.")
                console.error("Couldn't retrieve sponsor data.", e)
            })
    }

    async function writeSponsorData(data: SponsorWidgetComponents[]) {
        const filename = 'sponsors-data.json'
        const contentType = 'application/json'

        const jsonString = JSON.stringify(data)
        const jsonBlob = new Blob([jsonString], {
            type: contentType,
        })
        const jsonFile = new File([jsonBlob], filename, {
            type: contentType,
        })

        setLoading(true)
        if (isLocalEnv()) {
            await uploadFile({
                file: jsonFile,
                filename: filename,
            })
            setCachebuster(Date.now())
        } else {
            try {
                const response = await getSignedUrl({ filename, contentType })

                if (!response) {
                    throw Error('No response from server')
                }

                const headers = new Headers()
                headers.append('Cache-Control', `public, max-age=120`)

                const result = await fetch(response.url, {
                    method: 'PUT',
                    body: jsonFile,
                    headers,
                })

                if (result.status !== 200) {
                    toast({
                        title: 'Oh no something went wrong!',
                        description:
                            'There was an error uploading the file - please check the console and contact the dev team if the problem persists',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                    console.error(result)
                } else {
                    toast({
                        title: 'Success',
                        description: 'Sponsor has been updated',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    setCachebuster(Date.now())
                }
            } catch (e) {
                setLoading(false)
                console.error('Couldnt upload file / configure sponsor', e)
            }
        }
    }

    return (
        <Box>
            <Text fontSize={'xx-large'} fontWeight="bold">
                Sponsors
            </Text>
            <Text fontSize={'sm'} paddingBottom={4}>
                Settings to change sponsors app wide. Note that the{' '}
                <b>images are saved separately to the text fields</b>.
            </Text>
            {error ? (
                <Box>
                    <Text color="red">{`Error: ${error}`}</Text>
                    <Button onClick={fetchSponsorData}>Try Again</Button>
                </Box>
            ) : (
                <Flex gap={4} flexWrap="wrap" justifyContent="left">
                    {sponsors.map((sponsor) => {
                        return (
                            <SponsorWidget
                                key={sponsor.id}
                                handleSaveSponsor={handleSaveSponsor}
                                handleDeleteSponsor={handleDeleteSponsor}
                                sponsor={sponsor}
                                loading={loading}
                            />
                        )
                    })}
                    {newSponsor ? (
                        <SponsorWidget
                            key={newSponsor.id}
                            handleSaveSponsor={handleSaveSponsor}
                            handleDeleteSponsor={handleDeleteSponsor}
                            sponsor={newSponsor}
                            newSponsor={true}
                            loading={loading}
                        />
                    ) : (
                        <Flex width={480} minHeight={120}>
                            <Button
                                m={'auto'}
                                leftIcon={<MdAdd />}
                                onClick={handleClickNewSponsor}
                                disabled={loading}
                            >
                                New Sponsor
                            </Button>
                        </Flex>
                    )}
                </Flex>
            )}
        </Box>
    )
}
