import { Box, Button, Flex, Image, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import TheGameLogo from '../../assets/TheGameLogo'
import { environment } from '../../environments/environment'
import { isLocalEnv } from '../helpers/is-local-env'
import { useGetPresignedUrl } from '../hooks/useGetPresignedUrl'
import { useUploadPublicAssetLocal } from '../hooks/useUploadPublicAssetLocal'
import { useRemovePublicAssetLocal } from '../hooks/useRemovePublicAssetLocal'
import { FileControl } from './FileControl'
import { MdPublishedWithChanges, MdOutlineDeleteForever } from 'react-icons/md'
import { ImageUpload } from './ImageUpload'
import { StyledModal } from './StyledModal'

function ImageBox({ children, ...rest }) {
    return (
        <Flex
            width={480}
            direction="column"
            padding={4}
            border="1px #65707d77 solid"
            borderRadius={8}
            {...rest}
        >
            {children}
        </Flex>
    )
}

export function OfficialPartnerUpload() {
    const [uploadFile] = useUploadPublicAssetLocal()
    const [removeFile] = useRemovePublicAssetLocal()
    const [getSignedUrl] = useGetPresignedUrl()
    const [selectedImage, setSelectedImage] = useState<File | null>(null)
    const [cacheBuster, setCachebuster] = useState(Date.now())
    const [showPublishModal, setShowPublishModal] = useState(false)

    const toast = useToast()

    const onRemoveImageApply = async () => {
        const filename = 'officialpartner.png'
        const contentType = 'image/png'

        if (isLocalEnv()) {
            await removeFile({
                filename,
            })
            setCachebuster(Date.now())
        } else {
            try {
                const response = await getSignedUrl({
                    filename,
                    contentType,
                    method: 'DELETE',
                })

                if (!response) {
                    throw Error('No response from server')
                }

                const headers = new Headers()
                headers.append('Cache-Control', `public, max-age=120`)

                const result = await fetch(response.url, {
                    method: 'PUT',
                    headers,
                })

                if (result.status !== 200) {
                    toast({
                        title: 'Oh no something went wrong!',
                        description:
                            'There was an error deleteing the file - please check the console and contact the dev team if the problem persists',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                } else {
                    toast({
                        title: 'Success',
                        description: `Official Partner logo has been deleted`,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    setCachebuster(Date.now())
                }
            } catch {
                console.error('Couldnt delete file ')
            }
        }
    }

    const onImageApply = async (file: File | null) => {
        if (file === null) return

        const filename = 'officialpartner.png'
        const contentType = file.type

        setSelectedImage(file)

        if (isLocalEnv()) {
            await uploadFile({
                file,
                filename,
            })
            setCachebuster(Date.now())
        } else {
            try {
                const response = await getSignedUrl({ filename, contentType })

                if (!response) {
                    throw Error('No response from server')
                }

                const headers = new Headers()
                headers.append('Cache-Control', `public, max-age=120`)

                const result = await fetch(response.url, {
                    method: 'PUT',
                    body: file,
                    headers,
                })

                if (result.status !== 200) {
                    toast({
                        title: 'Oh no something went wrong!',
                        description:
                            'There was an error uploading the file - please check the console and contact the dev team if the problem persists',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                } else {
                    toast({
                        title: 'Success',
                        description: `Official Partner logo has been updated`,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    setCachebuster(Date.now())
                }
            } catch {
                console.error('Couldnt upload file / configure season')
            }
        }
    }

    return (
        <Box>
            <Text fontSize={'xx-large'} fontWeight="bold">
                Images & Assets
            </Text>
            <Text fontSize={'sm'}>App wide images and assets management</Text>
            <ImageBox
                mt="4"
                mb="4"
                border="1px #65707d77 solid"
                justifyContent="space-between"
                direction="column"
            >
                <Text fontSize={'x-large'} fontWeight="bold">
                    Official Partner Logo
                </Text>
                <Text fontSize={'sm'}>.png format, 100x100px</Text>
                <Flex direction="row" mt={4} justifyContent="space-between">
                    <Flex>
                        <Box
                            width={100}
                            marginRight={25}
                            border="1px #E2E8F0 solid"
                        >
                            <TheGameLogo />
                        </Box>
                        <Image
                            src={
                                selectedImage
                                    ? URL.createObjectURL(selectedImage)
                                    : isLocalEnv()
                                    ? `${environment.apiGatewayUrl}/public-assets/officialpartner.png?cachebust=${cacheBuster}`
                                    : `${environment.frontendUrl}/public-assets/officialpartner.png?cachebust=${cacheBuster}`
                            }
                            width={100}
                            objectFit="contain"
                            border={`1px ${
                                selectedImage ? 'orange' : 'lime'
                            } solid`}
                        />
                    </Flex>
                </Flex>
                <Flex direction="column">
                    <FileControl
                        buttonLabel="Upload"
                        onSelect={(file) => setSelectedImage(file)}
                        accept=".png"
                    />
                    <Button
                        marginTop={2}
                        colorScheme="blue"
                        onClick={() => onImageApply(selectedImage)}
                        disabled={selectedImage === null}
                        leftIcon={<MdPublishedWithChanges />}
                    >
                        Apply
                    </Button>
                    <Button
                        marginTop={2}
                        colorScheme="blue"
                        onClick={() => setShowPublishModal(true)}
                        leftIcon={<MdOutlineDeleteForever />}
                    >
                        Remove
                    </Button>
                </Flex>
            </ImageBox>
            <StyledModal
                size="lg"
                onClose={() => setShowPublishModal(false)}
                isOpen={showPublishModal}
                footer={
                    <Box>
                        <Button
                            onClick={() => setShowPublishModal(false)}
                            colorScheme="white"
                            variant="outline"
                        >
                            Cancel
                        </Button>
                        <Button
                            ml="4"
                            colorScheme="red"
                            onClick={async () => {
                                await onRemoveImageApply()
                                setShowPublishModal(false)
                            }}
                        >
                            Confirm
                        </Button>
                    </Box>
                }
            >
                <Box pt="5" display="flex" justifyContent="center">
                    <Text fontSize="large">
                        Are you sure you want to remove this image?
                    </Text>
                </Box>
            </StyledModal>
            <Flex justifyContent="space-between" flexWrap="wrap">
                <ImageBox>
                    <Text fontSize={'x-large'} fontWeight="bold" marginTop={8}>
                        Featured Image
                    </Text>
                    <Text fontSize={'sm'} mb={4}>
                        .png format, 838x838px
                    </Text>
                    <ImageUpload fileName={'featuredimage.png'}></ImageUpload>
                </ImageBox>
                <ImageBox>
                    <Text fontSize={'x-large'} fontWeight="bold">
                        Featured Image (small)
                    </Text>
                    <Text fontSize={'sm'} mb={4}>
                        .png format, 375x290px
                    </Text>
                    <ImageUpload
                        fileName={'featuredimage-small.png'}
                    ></ImageUpload>
                </ImageBox>
                <ImageBox>
                    <Text fontSize={'x-large'} fontWeight="bold">
                        Featured Image (mobile)
                    </Text>
                    <Text fontSize={'sm'} mb={4}>
                        .png format, 758x882px
                    </Text>
                    <ImageUpload
                        fileName={'featuredimage-mobile.png'}
                    ></ImageUpload>
                </ImageBox>
            </Flex>
        </Box>
    )
}
