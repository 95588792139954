import React from 'react'
import { Flex } from '@chakra-ui/react'
import { OfficialPartnerUpload } from './components/OfficialPartnerUpload'
import { Sponsors } from './Sponsors/SponsorsSection'

export function SettingsForm() {
    return (
        <Flex direction="column" px={'10'} marginBottom={48} gap={10}>
            <OfficialPartnerUpload />
            <Sponsors />
        </Flex>
    )
}
