import {
    Box,
    Button,
    Flex,
    Input,
    Link,
    Text,
    Textarea,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { MdCancel, MdDelete, MdEdit, MdUpload } from 'react-icons/md'
import { ImageUpload } from './ImageUpload'
import { StyledModal } from './StyledModal'

export interface SponsorWidgetComponents {
    id: string
    name?: string
    title?: string
    description?: string
    link?: string
}

function TextDefault({ children, ...rest }) {
    return (
        <Text lineHeight={1.2} wordBreak="break-word" {...rest}>
            {children}
        </Text>
    )
}

function Subtle({ children }) {
    return (
        <Text opacity={0.7} fontSize="xs">
            {children}
        </Text>
    )
}

export function SponsorWidget({
    sponsor,
    handleSaveSponsor,
    handleDeleteSponsor,
    newSponsor,
    loading,
}: {
    sponsor: SponsorWidgetComponents
    handleSaveSponsor: (sponsor: SponsorWidgetComponents) => {}
    handleDeleteSponsor: (sponsor: SponsorWidgetComponents) => {}
    newSponsor?: boolean
    loading: boolean
}) {
    const { id, name, title, link, description } = sponsor
    const [editable, setEditable] = useState(newSponsor || false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [newName, setNewName] = useState(name || '')
    const [newTitle, setNewTitle] = useState(title || '')
    const [newDescription, setNewDescription] = useState(description || '')
    const [newLink, setNewLink] = useState(link || '')

    function handleClickEdit() {
        setNewName(name || '')
        setNewTitle(title || '')
        setNewDescription(description || '')
        setNewLink(link || '')

        setEditable(!editable)
        if (newSponsor && editable) {
            // If this is a new sponsor then switching off edit means you want to cancel it altogether
            handleConfirmDelete()
        }
    }

    function handleClickDelete() {
        setShowDeleteModal(true)
    }

    async function handleConfirmDelete() {
        setShowDeleteModal(false)
        setEditable(false)
        await handleDeleteSponsor(sponsor)
    }

    async function handleClickSave() {
        await handleSaveSponsor({
            id,
            name: newName,
            title: newTitle,
            description: newDescription,
            link: newLink,
        })
        setEditable(false)
    }

    return (
        <>
            <Flex
                direction={'column'}
                padding={4}
                border="1px #65707d77 solid"
                borderRadius={8}
                width={480}
                minHeight={120}
                opacity={loading ? '0.6' : '1'}
            >
                <Flex
                    direction="column"
                    width={'100%'}
                    height={'100%'}
                    justifyContent="space-between"
                >
                    <Flex
                        flexDirection="column"
                        justifyContent="space-between"
                        height="100%"
                    >
                        <Flex direction="column" gap={2}>
                            <div>
                                <Subtle>Name</Subtle>
                                {editable ? (
                                    <Input
                                        placeholder={name}
                                        size="sm"
                                        value={newName}
                                        onChange={(e) =>
                                            setNewName(e.target.value)
                                        }
                                    />
                                ) : (
                                    <TextDefault
                                        fontSize={'large'}
                                        fontWeight="bold"
                                    >
                                        {`${name ?? '[No Name]'}`}
                                    </TextDefault>
                                )}
                            </div>
                            <div>
                                <Subtle>Title</Subtle>
                                {editable ? (
                                    <Input
                                        placeholder={title}
                                        size="sm"
                                        value={newTitle}
                                        onChange={(e) =>
                                            setNewTitle(e.target.value)
                                        }
                                    />
                                ) : (
                                    <TextDefault>{`${
                                        title ?? '[No Title]'
                                    }`}</TextDefault>
                                )}
                            </div>
                            <div>
                                <Subtle>Description</Subtle>
                                {editable ? (
                                    <Textarea
                                        placeholder={description}
                                        size="sm"
                                        value={newDescription}
                                        onChange={(e) =>
                                            setNewDescription(e.target.value)
                                        }
                                    />
                                ) : (
                                    <TextDefault>{`${
                                        description ?? '[No Description]'
                                    }`}</TextDefault>
                                )}
                            </div>
                            <div>
                                <Subtle>Link</Subtle>
                                {editable ? (
                                    <Input
                                        placeholder={link}
                                        size="sm"
                                        value={newLink}
                                        onChange={(e) =>
                                            setNewLink(e.target.value)
                                        }
                                    />
                                ) : (
                                    <Link
                                        href={link}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <TextDefault>{`${
                                            link ?? '[No Link]'
                                        }`}</TextDefault>
                                    </Link>
                                )}
                            </div>
                        </Flex>
                        <Flex justifyContent="space-between" marginTop={4}>
                            <Button
                                onClick={() => handleClickEdit()}
                                leftIcon={editable ? <MdCancel /> : <MdEdit />}
                                disabled={loading}
                            >
                                {editable ? 'Cancel' : 'Edit'}
                            </Button>
                            {editable && (
                                <Flex gap={2}>
                                    {!newSponsor && (
                                        <Button
                                            onClick={() => handleClickDelete()}
                                            colorScheme="red"
                                            leftIcon={<MdDelete />}
                                            disabled={loading}
                                        >
                                            Delete
                                        </Button>
                                    )}
                                    <Button
                                        onClick={() => handleClickSave()}
                                        colorScheme="blue"
                                        leftIcon={<MdUpload />}
                                        disabled={loading}
                                    >
                                        Publish
                                    </Button>
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                    <Flex width={'100%'} direction="column" mt={4}>
                        <ImageUpload
                            fileName={`sponsor-image-${id}.png`}
                        ></ImageUpload>
                    </Flex>
                </Flex>
            </Flex>
            <StyledModal
                size="lg"
                onClose={() => setShowDeleteModal(false)}
                isOpen={showDeleteModal}
                footer={
                    <Box>
                        <Button
                            onClick={() => setShowDeleteModal(false)}
                            colorScheme="white"
                            variant="outline"
                        >
                            Cancel
                        </Button>
                        <Button
                            ml="4"
                            colorScheme="red"
                            onClick={handleConfirmDelete}
                            leftIcon={<MdDelete />}
                        >
                            Delete
                        </Button>
                    </Box>
                }
            >
                <Box marginTop={4}>
                    <Text fontSize={'large'}>{`Delete '${name}'?`}</Text>
                </Box>
            </StyledModal>
        </>
    )
}
